import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Button from './Button'
import PulseLoaderWithBackground from './PulseLoaderWithBackground'
import Sizes from './Sizes'
import chevron from '../assets/icons/chevron.svg'
import redoBlack from '../assets/icons/redo-black.svg'
import imperialChart from '../data/stockings/imperial-stockings.json'
import metricChart from '../data/stockings/metric-stockings.json'
import { getChart } from '../helpers'
import styles from '../styles/CalculatorQuiz.module.css'

const StockingsController = () => {
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [size, setSize] = useState('')
  const [unavailableSize, setUnavailableSize] = useState(false)
  const [chart, setChart] = useState('')
  const [lang, setLang] = useState('')
  const [sizesData, setSizesData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    setChart(getChart(queryParams.get('chart')))
    setLang(queryParams.get('lang') ?? 'en')
    if (['us', 'uk'].includes(chart)) {
      setSizesData(imperialChart)
    } else {
      setSizesData(metricChart)
    }
  }, [location, chart, sizesData])

  const { t, i18n } = useTranslation()
  useEffect(() => {
    const setLanguage = () => {
      i18n.changeLanguage(lang)
    }
    setLanguage()
  }, [lang, i18n])

  const onChangeHandler = (e, setter) => {
    setSize('')
    setUnavailableSize(false)
    setter(e.target.value)
  }

  const calculateSize = () => {
    setIsLoading(true)
    const s = sizesData.weights[weight][height]
    if (s.length > 0) {
      setTimeout(() => {
        setIsLoading(false)
        setSize(s)
      }, 300)
      return
    }
    setTimeout(() => {
      setUnavailableSize(true)
      setIsLoading(false)
    }, 300)
  }

  const reset = () => {
    setHeight('')
    setWeight('')
    setSize('')
    setUnavailableSize(false)
  }

  return (
    <div className={styles['beige-container']}>
      <div className={`${styles['stockings-wrapper']}`}>
        <h2 className={styles['stockings-title']}>{t('stockings_size_guide')}</h2>
        <div className={styles['inner-grid-wrapper']}>
          {!size && !unavailableSize && !isLoading && (
            <>
              <div className={`${styles['grid-area-a']} ${styles['input-wrapper']}`}>
                <p className={styles['input-label']}>{t('height')}</p>
                <div className={`${styles['select-wrapper']} ${styles['select-wrapper-small']}`}>
                  <p className={styles['selected-value']}>
                    {height || ''}
                    {!height && '-/-'}
                  </p>
                  <img src={chevron} alt="chevron" className={styles.chevron} />
                  <select
                    className={styles.select}
                    onChange={(e) => onChangeHandler(e, setHeight)}
                    value={height || ''}>
                    <option value="" disabled hidden>
                      {t('question_height', { unit: ['uk', 'us'].includes(chart) ? 'ft & inches' : 'cm' })}
                    </option>
                    {sizesData.heights &&
                      sizesData.heights.map((key) => {
                        return (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
              <div className={`${styles['grid-area-b']} ${styles['input-wrapper']}`}>
                <p className={styles['input-label']}>{t('weight')}</p>
                <div className={`${styles['select-wrapper']} ${styles['select-wrapper-small']}`}>
                  <p className={styles['selected-value']}>
                    {weight || ''}
                    {!weight && '-/-'}
                  </p>
                  <img src={chevron} alt="chevron" className={styles.chevron} />
                  <select
                    className={styles.select}
                    onChange={(e) => onChangeHandler(e, setWeight)}
                    value={weight || ''}>
                    <option value="" disabled hidden>
                      {t('question_weight', { unit: ['uk', 'us'].includes(chart) ? 'lbs' : 'kg' })}
                    </option>
                    {sizesData.weights &&
                      Object.entries(sizesData.weights).map(([key]) => {
                        return (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
            </>
          )}
          {!size && !unavailableSize && !isLoading && (
            <div className={styles['grid-area-c']}>
              <Button
                text={t('get_results')}
                onClick={size || unavailableSize ? reset : calculateSize}
                disabled={height && weight ? false : true}
                variant="black"
              />
            </div>
          )}
          {(size || unavailableSize) && (
            <div className={styles['stockings-bottom-button-wrapper']}>
              <Button
                text={t('try_again')}
                onClick={reset}
                disabled={false}
                variant="recalculate"
                image={redoBlack}
                arrow="together"
              />
            </div>
          )}
          <div
            className={`${styles['min-h-size-wrapper']} ${styles['stockings-results-wrapper']} ${
              !size && !unavailableSize ? styles['display-none'] : ''
            }`}>
            {!isLoading && size && (
              <div className={styles['size-wrapper']}>
                <img
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png"
                  alt="Partying Face"
                  width="35"
                  height="35"
                />
                <p>{t('size_suggestion')}</p>
                <Sizes size={size} chart={chart} lang={lang} paramChart={chart} />
              </div>
            )}
            {!isLoading && unavailableSize && (
              <div className={styles['size-wrapper']}>
                <img
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png"
                  alt="Thinking Face"
                  width="35"
                  height="35"
                />
                <p>{t('stocking_size_suggestion_unavailable')}</p>
              </div>
            )}
            {isLoading && (
              <div className={styles['loading-wrapper']}>
                <PulseLoaderWithBackground isLoading={isLoading} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockingsController
